<template>
  <div class="Polaris-FormLayout__Item">
    <div class="Polaris-Labelled__LabelWrapper">
      <div class="Polaris-Label"><label :for="id + 'input' " :id="id + 'label'" class="Polaris-Label__Text"
          v-text="label"> </label></div>
    </div>
    <div class="Polaris-Connected">

      <div class="Polaris-Connected__Item Polaris-Connected__Item--primary">
        <VueEditor :value="value" :editor-toolbar="CONST.CUSTOMTOOLBAR" />
      </div>
    </div>
    <div class="Polaris-Labelled__Error" v-if="invalid">
      <div class="Polaris-InlineError">
        <div class="Polaris-InlineError__Icon">
          <span class="Polaris-Icon">
            <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
              <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z" fill-rule="evenodd"></path>
            </svg>
          </span>
        </div>
        {{ invalid }}
      </div>
    </div>
    <div class="Polaris-Labelled__HelpText" :id="id + 'helpText'" v-text="helpText" v-if="helpText"></div>
  </div>
</template>

<script>
  import {
    VueEditor
  } from "vue2-editor";
  export default {
    components: {
      VueEditor,
    },
    props: {
      value: {
        type: String,
      },
      label: {
        type: String,
      },
      helpText: {
        type: String,
      },
      invalid: {
        type: String,
        default: null,
      },
    },
    computed: {
      id() {
        return this._uid;
      }
    }
  }
</script>